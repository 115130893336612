<template>
  <div class="userIndex-box inform-page">
    <div class="content">
      <div class="lever-one"></div>
      <div class="lever-two  ">
        <div class="user-search flex">
          <div class="searchIcon cursor" @click="goList">
            <img src="@/assets/img/search.png" />
          </div>
          <input
            type="text"
            placeholder="请输入站内信名称搜索"
            class="searchInput"
            v-model="searchVal"
            @keyup.enter="goList"
          />
        </div>
        <div></div>
        <User bgColor="#fff" class="pl30"></User>
      </div>
      <div class="back-box">
        <div class="back flex f20 cup" @click="goBack">
          <img src="@/assets/img/back.png" />
          <span>后退</span>
        </div>
      </div>
      <div class="inform-detail-content">
        <div class="title">
          {{ info.title }}
        </div>
        <div class="timer">{{ time }} 发布</div>
        <div class="edit-content" v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/components/user.vue'
import { systemInfoDetailApi, setReadedApi, getNoReadedApi } from '@/api/user'
import { setItem, getItem } from '@/assets/js/storage.js'
export default {
  name: 'course',
  components: {
    User
  },
  data() {
    return {
      time: '',
      info: {},
      searchVal: ''
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.getInit(id)
  },
  methods: {
    getInit(id) {
      systemInfoDetailApi({ id }).then(res => {
        if (res.code === 0) {
          this.info = res.data
          this.time = this.formDate(res.data.time)
          this.setReadM(id)
        }
      })
    },
    goList() {
      this.$router.replace({
        name: 'userInform',
        query: {
          searchVal: this.searchVal,
          isDetail: 1
        }
      })
    },
    // 设置成已读
    setReadM(id) {
      setReadedApi({ ids: id })
      getNoReadedApi({}).then(res => {
        if (res.code === 0) {
          if (res.data.qty === 0) {
            let userInfo = getItem('user')
            if (!userInfo) {
              userInfo = {}
            }
            userInfo.unreadMsgQty = 0
            setItem('user', userInfo)
            this.$store.dispatch('setUserModal')
          }
        }
      })
    },
    formDate(datetime) {
      var date = new Date(datetime * 1000)
      var year = date.getFullYear(),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        sdate = ('0' + date.getDate()).slice(-2),
        hour = ('0' + date.getHours()).slice(-2),
        minute = ('0' + date.getMinutes()).slice(-2),
        second = ('0' + date.getSeconds()).slice(-2)
      var result =
        year +
        '-' +
        month +
        '-' +
        sdate +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      // 返回
      return result
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped></style>
